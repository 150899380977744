import React from "react";

function IconDrupal(props) {

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         style={props.style}
         className={props.className}
         height={props.height}
         width={props.width}
         viewBox="0 0 512 512"
    >
<path fill={props.fill} d="M243.625,313.033c-41.072,0-74.352,33.821-74.352,75.505c0,41.691,33.28,75.517,74.352,75.517       c41.066,0,74.354-33.825,74.354-75.517C317.979,346.854,284.691,313.033,243.625,313.033L243.625,313.033z M243.625,313.033" />
<path fill={props.fill} d="M336.205,298.558c22.01,23.354,35.549,55.026,35.549,89.953       c0,44.261-21.713,83.227-54.881,106.739c61.381-19.205,112.172-66.037,134.871-122.765       c31.469-78.508,2.121-137.542-46.971-191.101c1.551,6.875,2.385,14.174,2.385,21.502       C407.008,248.403,377.125,286.652,336.205,298.558L336.205,298.558z M336.205,298.558" />
<path fill={props.fill} d="M252.096,203.008c0,31.936,25.527,57.745,56.846,57.745c31.316,0,56.994-25.927,56.994-57.745       c0-31.941-25.527-57.751-56.846-57.751C277.779,145.257,252.096,171.033,252.096,203.008L252.096,203.008z M252.096,203.008" />
<path fill={props.fill} d="M145.586,472.343c-18.755-22.646-30.062-51.859-30.062-83.805       c0-66.627,49.243-121.471,112.862-129.069c-10.861-16.092-17.33-35.56-17.33-56.461c0-55.032,43.871-99.441,98.063-99.441       c4.506,0,8.889,0.299,13.125,0.868c-32.033-28.083-64.064-56.579-89.33-87.684c12.828,136.366-122.311,86.816-172.416,212.605       C27.063,313.6,57.246,417.732,145.586,472.343L145.586,472.343z M145.586,472.343" />    </svg>
  );
}

export default IconDrupal;